module.exports = {
  title: {
    login: '登入帳號',
    personSetting: '個人設定',
    forgetPassword: '忘記密碼',
    homepage: '首頁',
  },
  text: {
    login: '登入',
    confirm: '確認',
    sendEmail: '發送',
    forgetPassword: '忘記密碼',
    noData: '暫無資料',
  },
  label: {
    name: '姓名',
    account: '用戶名',
    email: '電子信箱',
    password: '密碼',
    confirmPassword: '確認密碼',
  },
  placeholder: {
    choose: '請選擇',
    account: '輸入用戶名',
    name: '請輸入姓名',
    email: '請輸入電子信箱',
    password: '請輸入6-20位英文、數字、特殊符號任意組合',
    findName: '姓名搜尋',
    findUnitName: '搜尋服務人員姓名',
  },
  hint: {
    addAccount: '新增下一步後，系統將自動發送確認郵件給該用戶，邀請加入國泰健康管理服務平台',
  },
}
